/* Global Styles */
body {
    background-color: #ddd;
}

/* Base font size */
html {
    font-size: 16px;
}

/* Headings */
h1 {
    font-size: 2.5rem;  /* 40px */
    line-height: 1.2;
    margin-bottom: 0.5rem;
}

h2 {
    font-size: 2rem;  /* 32px */
    line-height: 1.3;
    margin-bottom: 0.75rem;
}

h3 {
    font-size: 1.75rem;  /* 28px */
    line-height: 1.4;
    margin-bottom: 0.75rem;
}

h4 {
    font-size: 1.5rem;  /* 24px */
    line-height: 1.5;
    margin-bottom: 1rem;
}

h5 {
    font-size: 1.25rem;  /* 20px */
    line-height: 1.6;
    margin-bottom: 1rem;
}

h6 {
    font-size: 1rem;  /* 16px */
    line-height: 1.6;
    margin-bottom: 1rem;
}

/* Paragraph */
p {
    font-size: 1rem;  /* 16px */
    line-height: 1.6;
    margin-bottom: 1rem;
    color: #333;  /* Dark gray color */
}

/* App Styles */
.App {
    text-align: center;
}

.App-logo {
    height: 40vmin;
    pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
    .App-logo {
        animation: App-logo-spin infinite 20s linear;
    }
}

@keyframes App-logo-spin {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(360deg);
    }
}

.App-header {
    background-color: #282c34;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: calc(10px + 2vmin);
    color: white;
}

.App-link {
    color: #61dafb;
}

/* Header */
.header {
    display: flex;
    align-items: center;
}

.logo {
    margin-right: 20px;
    height: 85px;
}

/* Pill Tags */
.pill-container {
    display: flex;
    flex-direction: column;
    gap: 9px;
    align-self: end;
}

.pill {
    display: inline-block;
    padding: 5px 10px;
    border-radius: 5px;
    color: white;
    font-size: 12px;
    font-weight: bold;
    width: fit-content;
}

.total-entries {
    background-color: #5749d2;
}

.filtered-entries {
    background-color: #d24980;
}

.download-info {
    margin: 0;
    font-size: 12px;
    font-weight: bold;
}

/* Tooltip */
.tooltip {
    position: relative;
    display: inline-block;
    cursor: pointer;
}

.tooltip .tooltiptext {
    visibility: hidden;
    width: auto;
    background-color: #49d262;
    color: #000;
    text-align: center;
    border-radius: 6px;
    padding: 2px 4px;
    position: absolute;
    z-index: 1;
    bottom: -15px;
    left: 5px;
    opacity: 0;
    transition: opacity 0.3s;
    font-size: 10px;
}

.tooltip:hover .tooltiptext {
    visibility: visible;
    opacity: 1;
}

/* Login */
.login-logo {
    margin-bottom: 50px;
    margin-left: 20px;
}

.login-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100vh;
    background-color: #ddd;
    color: #000;
}

.login-container h1 {
    margin-bottom: 20px;
}

.login-container form {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.login-container input {
    padding: 10px;
    margin-bottom: 20px;
    border: 2px solid #49b8d2;
    border-radius: 5px;
    font-size: 16px;
}

.login-container button {
    padding: 10px 20px;
    background-color: #49b8d2;
    color: #fff;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-size: 16px;
    transition: background-color 0.3s ease;
    width: 100%;
    text-transform: uppercase;
    font-weight: bold;
}

.login-container button:hover {
    background-color: #358395;
}

.ver {
    margin-top: 50px;
    color: #fff;
    font-size: 12px;
    font-weight: bold;
    background-color: #d24980;
}

/* Header Button Styles */
.header-button {
    position: absolute;
    right: 21px;
    padding: 10px 20px;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s ease;
    text-transform: uppercase;
    font-weight: bold;
    width: 130px;
}

/* Specific Styles for Logout Button */
.logout-button {
    top: 21px;
    background-color: #d24980;
}

.logout-button:hover {
    background-color: #b13d6c;
}

/* Back Button */

.back-button {
    background-color: #009900;
    padding: 5px;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s ease;
    text-transform: uppercase;
    font-weight: bold;
}

.back-button:hover {
    background-color: #008500;
}

.charts-button {
    top: 68px;
    background-color: #009900;
}

.charts-button:hover {
    background-color: #008500;
}

.toggle-button {
    padding: 10px 20px;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s ease;
    text-transform: uppercase;
    font-weight: bold;
    margin: 0 5px;
}

.county-toggle {
    background-color: #6666FF;
}

.county-toggle:hover {
    background-color: #5252CC;
}

.city-toggle {
    background-color: #FF6666;
}

.city-toggle:hover {
    background-color: #CC5252;
}

/* Tables */
.table-container {
    padding: 20px;
}

table {
    table-layout: auto; /* Allow table to adjust columns automatically */
    width: 100%;
    border-collapse: collapse;
    margin-top: 20px;
    border: 2px solid #999;
}

th, td {
    border: 1px solid #ddd;
    padding: 8px;
    text-align: left;
    white-space: nowrap; /* Prevent text wrapping */
    overflow: hidden;
    text-overflow: ellipsis; /* Add ellipsis to content that overflows */
}

th {
    background-color: #f2f2f2;
    white-space: nowrap;
    text-align: left;
    font-weight: bold;
    font-size: 12px;
}

table th input, table td input {
    width: 100%; /* Subtract padding (8px on both sides) */
    box-sizing: border-box;    /* Ensure padding is included in the width calculation */
}

table th input::placeholder, table td input::placeholder  {
    color: transparent;
}

tr:nth-child(odd) {
    background-color: #fff;
}

tr:nth-child(even) {
    background-color: #f9f9f9;
}

tr:hover {
    background-color: #f1f1f1;
}

.hidden-column {
    display: none;
}

.controls-row .controls {
    display: flex;
    align-items: center;
    gap: 10px;
    justify-content: flex-start;
    position: relative;
}

.download-icon-wrap {
    overflow: unset;
}

.download-icon {
    cursor: pointer;
    margin: auto;
    transition: fill 0.3s ease, transform 0.3s ease;
    text-align: center;
}

.download-icon:hover {
    transform: scale(1.25);
}

/* Status Messages */
.loading, .no-results {
    font-weight: bold;
}

/* Clickable Links */
.clickable {
    text-decoration: underline solid #49b8d3 2px;
    cursor: pointer;
}

.spinner {
    border: 16px solid #f3f3f3;
    border-top: 16px solid #3498db;
    border-radius: 50%;
    width: 120px;
    height: 120px;
    animation: spin 2s linear infinite;
    margin: auto;
  }
  
  @keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
  }
  
  .spinner-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
  }

  /* HELPER */
  .centered {
    text-align: center;
  }
  

/* Media Queries */
@media only screen and (max-width: 500px) {
    .table-container {
        padding: 10px;
    }
    .header {
        height: 58px;
    }
    .logo {
        height: 40px;
        margin-right: 10px;
    }
    .header-button {
        padding: 2px;
        width: 100px;
    }
    .logout-button {
        top:19px;
        font-size: 10px;
    }
    .charts-button {
        top: 42px;
        font-size: 10px;
    }
    .pill {
        padding: 5px;
        font-size: 10px;
    }
    .pill-container {
        align-self: center;
    }
    .download-info {
        display: none;
    }
}

